<!--设备模块--舒适控制组页-->
<template>
  <div id="controllGroupComfort" ref="controllGroupComfort">
    <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%">
        <my-navder
          :list="list"
          @select="getMsgFormSon"
          :style="style"
        ></my-navder>
      </a-spin>
    </div>
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">
          <my-headertitle>{{this.$t('controllGroupComfort.a1')}}</my-headertitle>
          <div class="info">
            <my-nameupdate
              :title="nodeParentName"
              :name="nodeName"
              @confirm="nameUpdate"
              :state="state"
              :loading="loadingOK"
              ref="children"
            ></my-nameupdate>
            <div>
              <span>{{this.$t('controllGroupComfort.a2')}}</span>
              <span>{{ nodeId }}</span>
            </div>
          </div>
          <my-tabletitle>{{this.$t('controllGroupComfort.a3')}}</my-tabletitle>
          <my-table :data="monitorList"></my-table>
          <my-tabletitle>{{this.$t('controllGroupComfort.a4')}}</my-tabletitle>
          <my-table :data="measureList"></my-table>
        </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="select" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{this.$t('controllGroupComfort.a5')}}</span>
      </div>
      <div class="footer" v-if="id">        
        <a-button type="primary" @click="toTmEditorOut">{{this.$t('controllGroupComfort.a8')}}</a-button>
        <a-button type="primary" @click="toTmEditorIn">{{this.$t('controllGroupComfort.a7')}}</a-button>
        >
      </div>
    </div>    
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import navdar from "../../components/Navdar/vertical";
import headerTitle from "../../components/Title/headerTitle";
import deviceTable from "./deviceTable";
import nameUpdate from "../../components/Name/nameUpdate";
import { lightTimetableListKT,getComfortInfo,updateScheduleName } from "../../api/device";
import select from "../../components/Select/select";

export default {
  data() {
    return {
      list: [],
      arr: [],
      MAX_NUM: 1,
      timer: null,
      carriedOut: true,
      pos: 0,
      spinning: false,
      id: undefined,//包含类型和时间表ID
      loading: false,
      schType: 9,
      nodeId: undefined,//树节点ID
      nodeName: undefined,//树节点名称
      nodeParentName: undefined,//父节点名称
      measureList:[],
      monitorList:[],
      visible: false,
      confirmLoading: false,
      copyKey: undefined,
      state: false,
      isActive: undefined,
      loadingOK: false,
    };
  },
  // 获取侧边栏数据同时执行虚拟化列表的方法
  async mounted() {
    this.spinning = true;
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();    
    this.spinning = false;
  },
  computed: {
    // 侧边栏的高度计算和padding-top计算
    style: function () {
      if (this.pos !== 0) {
        return `height:${48 * this.arr.length}px;padding-top:${
          this.pos * 48 - 96
        }px`;
      } else {
        return `height:${48 * this.arr.length}px;padding-top:0px`;
      }
    },
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {
 
      this.getdata();
    },
    p1: function () {
      this.selectList = this.arr.slice(this.p1, this.p2);
    },
    '$route'(to,from){
      this.spinning = true;
      // 进入页面时调用获取页面左侧列表方法
      this.navderList();    
      this.spinning = false;
    },
  },
  //缓存页面勾子函数
  activated(){
    this.getdata();
  },
  methods: {
    // 获取页面左侧列表方法
    navderList() {
      
      let name = this.$route.name;
      if(name=="VRFAC_FAC_SAITEKI_GR" || name=="LIGHTING_FAC_SAITEKI_GR"||name=="ENVIRONMENT_FAC_SAITEKI_GR"||name=="FACILITIES_FAC_SAITEKI_GR")
        this.schType=10;
      else
        return;
      console.log("controllGroupComfort--获取数据：",this.schType);
      this.spinning = true;
      let par = {
        siteId: this.$route.query.id,
        schType: this.schType,
      };
      lightTimetableListKT(par)
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          data.key = "sub1";
          this.list.length=0;
          this.list.push(data);          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
    // 加载页面数据的方法    
    getdata() {
      this.loading = true;  
      let par = {
        comfortId: this.nodeId?this.nodeId:0,
      };
      console.log("getComfortInfo--par",par);    
      getComfortInfo(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("getComfortInfo--data",data);
          this.measureList=data.measureList;
          this.monitorList=data.monitorList;
          for (let i = 0; i < this.monitorList.length; i++) {
            this.monitorList[i].key = i + 1;
          }
          for (let i = 0; i < this.measureList.length; i++) {
            this.measureList[i].key = i + 1;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.id = data;
      let number = this.id.split(",");
      this.nodeId = number[1];
      let schedule = this.list[0].children[0].children;
      this.nodeParentName = this.list[0].children[0].title;
      for(var i=0;i<schedule.length;i++){
          if(this.id == schedule[i].key){
            this.nodeName = schedule[i].title;
            break;
          }
      }
      console.log("controllGroupComfort--获取选择时间表名称：",this.nodeParentName);      
    },
    // 虚拟化侧边栏列表
    virtual() {
      if (this.carriedOut) {
        let height = this.$refs.container.clientHeight;
        this.MAX_NUM = Math.ceil(height / 48);
        let scrollTop = this.$refs.container.scrollTop;
        this.pos = Math.round(scrollTop / 48);
        console.log("controllGroupComfort-virtual",this.list);
        this.list[0].children[0].children = this.arr.slice(
          this.pos,
          this.pos + this.MAX_NUM
        );
        this.carriedOut = false;
        this.timer = setTimeout(() => {
          this.carriedOut = true;
          clearTimeout(this.timer);
        }, 50);
      }
    },
    // 空调舒适控制页跳转至温度测量点编辑页
    toTmEditorIn() {
      let singnalType = 16;
      this.$router.push({
        path: "/homepage/air-condition/terminalEditor",
        query: { id: this.$route.query.id, nodeId: this.nodeId,schType:this.schType,type:singnalType,
                nodeName:this.nodeName },
      });
    },  
    // 空调舒适控制跳转至空调监控点编辑页
    toTmEditorOut() {
      let singnalType = 15;
      this.$router.push({
        path: "/homepage/air-condition/terminalEditor",
        query: { id: this.$route.query.id, nodeId: this.nodeId,schType:this.schType,type:singnalType,
                nodeName:this.nodeName },
      });
    },     
    // 更新名称
    nameUpdate(name) {
      this.loadingOK = true;
      let par = {
        scheId: this.nodeId,
        scheType: this.schType,
        newName:name,
      };
      console.log(par);
      updateScheduleName(par)
        .then((res) => {
          this.loadingOK = false;
          this.$refs.children.changestate();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },    
  },  
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": navdar,
    "my-headertitle": headerTitle,
    "my-table": deviceTable,
    "my-nameupdate": nameUpdate,
    "my-select": select,
  },
};
</script>
<style scoped>
#controllGroupComfort {
  height: 100%;
  display: flex;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.info-hidden {
  display: flex;
  height: 60px;
  align-items: center;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  /* width: 120px; */
  margin: 0px 10px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.d {
  /* display: flex; */
  position: relative;
}
.c {
  position: absolute;
  width: 600px;
}
</style>